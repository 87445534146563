html {
    height: 100%;
}

body {
    min-height: 100%;
}

#root {
    min-height: 100vh;
    background-color: #f6f6ef;
    display: flex;
    flex-direction: row;
}

.btn-xlarge {
    font-size: 4rem;
    line-height: normal;
}

.btn-large {
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
}


.btn:focus {
    box-shadow: none;
}


a {
    text-decoration: none !important;
}

.hr {
    color: black;
    background-color: black;
    height: 1px !important;
    width: 30%;
}

.prompt-height {
    min-height: calc(1.5em + 0.75rem + 2px);
}